.Tabbar {
  position: fixed;
  z-index: var(--vkui_internal--z_index_tabs);
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--vkui_internal--tabbar_height);
  padding-bottom: var(--vkui_internal--safe_area_inset_bottom);
  box-sizing: content-box;
  background: var(--vkui--color_background_tertiary);
  display: flex;
  overflow: hidden;
}

.Tabbar--shadow {
  box-shadow: var(--vkui--elevation3);
}

/**
 * iOS
 */

.Tabbar--ios.Tabbar--shadow {
  box-shadow: none;
}

.Tabbar--ios.Tabbar--shadow::before {
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--vkui_internal--thin_border);
  background: var(--vkui--color_separator_primary_alpha);
  content: '';
}
