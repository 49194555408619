.WriteBar {
  background: var(--vkui--color_background_modal);
}

.WriteBar--shadow {
  box-shadow: var(--vkui--elevation3);
}

.WriteBar__before,
.WriteBar__form,
.WriteBar__after {
  display: flex;
  align-items: flex-end;
}

.WriteBar__form {
  min-height: 52px;
}

.WriteBar__formIn {
  flex: 1;
  min-width: 0;
  display: flex;
  position: relative;
}

.WriteBar__textarea {
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  min-width: 0;
  margin: 0;
  background: transparent;
  border: 0;
  resize: none;
  color: var(--vkui--color_text_primary);
  -webkit-appearance: none;
  max-height: 120px;
}

.WriteBar__textarea::placeholder {
  color: var(--vkui--color_text_subhead);
  /* Для Firefox */
  opacity: 1;
}

.WriteBar__textarea:focus {
  outline: none;
}

.WriteBar__inlineAfter {
  display: flex;
  align-items: flex-end;
}

.WriteBar__before {
  padding-left: 4px;
}

.WriteBar__after {
  padding-right: 4px;
}

.WriteBar__textarea {
  height: 52px;
  padding: 16px 12px;
}

/**
 * iOS
 */
.WriteBar--ios {
  background: var(--vkui--color_background_content);
}

.WriteBar--ios .WriteBar__before,
.WriteBar--ios .WriteBar__after {
  padding: 0 4px;
}

.WriteBar--ios .WriteBar__formIn {
  box-sizing: border-box;
  background-color: var(--vkui--color_write_bar_input_background);
  border: var(--vkui_internal--thin_border) solid var(--vkui--color_write_bar_input_border);
  border-radius: 18px;
  margin: 8px 0;
}

.WriteBar--ios .WriteBar__formIn:first-child {
  margin-left: 12px;
}

.WriteBar--ios .WriteBar__inlineAfter {
  /* чтобы правильно выровнять иконку, нужно учитывать border */
  --vkui_internal--writebar-right-gap: calc(-1 * var(--vkui_internal--thin_border));
  --vkui_internal--writebar-vertical-gap: calc(var(--vkui_internal--writebar-right-gap) - 8px);

  margin-right: var(--vkui_internal--writebar-right-gap);
  margin-top: var(--vkui_internal--writebar-vertical-gap);
  margin-bottom: var(--vkui_internal--writebar-vertical-gap);
}

.WriteBar--ios .WriteBar__textarea {
  height: 36px;
  padding: 6px 0 6px 12px;
}
