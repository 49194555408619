.text-stroke-blue {
    text-shadow: -1px -1px 0 #1472FF, 1px -1px 0 #1472FF, -1px 1px 0 #1472FF, 1px 1px 0 #1472FF;
}

.text-stroke-orange {
    text-shadow: -1px -1px 0 #BC7200, 1px -1px 0 #BC7200, -1px 1px 0 #BC7200, 1px 1px 0 #BC7200;
}

.button-shadow-blue {
    box-shadow: 0px 2px 9px 2px rgba(0, 0, 0, 0.25), inset 0px 0px 1px 2px #008BD9, inset 0px -2px 5px 8px #00A2FD, inset 0px 2px 2px 8px #81E5FF;
}

.button-shadow-orange {
    box-shadow: 0px 2px 9px 2px rgba(0, 0, 0, 0.25), inset 0px 0px 1px 5px #D27E00, inset 0px -2px 12px 8px #DE8500, inset 0px 2px 2px 8px #FFE589;
}

.outline-radius {
    -moz-outline-radius: 45px;
}