.ScrollArrow {
  cursor: pointer;
  user-select: auto;
  height: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: opacity 0.15s;
  transition-timing-function: var(--vkui--animation_easing_platform);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ScrollArrow__icon {
  position: relative;
  background-color: var(--vkui--color_background_modal);
  color: var(--vkui--color_icon_secondary);
  box-shadow: var(--vkui--elevation3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScrollArrow--size-m .ScrollArrow__icon {
  width: 28px;
  height: 28px;
  border-radius: 14px;
}

.ScrollArrow--size-l .ScrollArrow__icon {
  width: 40px;
  height: 40px;
  border-radius: 24px;
}

.ScrollArrow--direction-left {
  padding-left: var(--vkui--size_base_padding_horizontal--regular, 16px);
  left: 0;
}

.ScrollArrow--direction-right {
  padding-right: var(--vkui--size_base_padding_horizontal--regular, 16px);
  right: 0;
}
