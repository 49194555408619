/*@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;700;800&display=swap');*/
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'MPLUSRounded1c';
    src: url('https://crossword.cookieapp.ru/storage/assets/fonts/MPLUSRounded1c-Regular.ttf') format('truetype'); /* Путь относительно index.css */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MPLUSRounded1c';
    src: url('https://crossword.cookieapp.ru/storage/assets/fonts/MPLUSRounded1c-Bold.ttf') format('truetype'); /* Путь относительно index.css */
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'MPLUSRounded1c';
    src: url('https://crossword.cookieapp.ru/storage/assets/fonts/MPLUSRounded1c-ExtraBold.ttf') format('truetype'); /* Путь относительно index.css */
    font-weight: 800;
    font-style: normal;
}

* {
    font-family: "MPLUSRounded1c" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color : white;
}

html, body {
    height : 100%;
    margin : 0;
    overflow: hidden;
    overscroll-behavior-y: none;
}

body.dragging {
    cursor: pointer !important;
}

h1, h2, h3, h4, h5, p {
    margin: 0
}

.popup {
    animation-name: popup-animation;
    animation-duration: 0.15s;
    animation-fill-mode: forwards;
}

.popout {
    animation-name: popout-animation;
    animation-duration: 0.15s;
    animation-fill-mode: forwards;
}

@keyframes popup-animation {
    0% {
        transform: translate3d(0, 100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes popout-animation {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, 100%, 0);
    }
}