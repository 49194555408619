.WriteBarIcon {
  background: none;
  border: 0;
  position: relative;
  color: var(--vkui--color_icon_secondary);
  height: 52px;
  width: 44px;
  cursor: pointer;
  border-radius: var(--vkui--size_border_radius--regular);
  margin: 0;
  padding: 0;
}

.WriteBarIcon__in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.WriteBarIcon:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.WriteBarIcon__active {
  opacity: 0.6;
}

.WriteBarIcon__counter {
  position: absolute;
  top: 6px;
  right: 2px;
  box-shadow: 0 0 0 2px var(--vkui--color_background_modal);
}

.WriteBarIcon.WriteBarIcon--mode-send,
.WriteBarIcon.WriteBarIcon--mode-done {
  color: var(--vkui--color_icon_accent);
}

/**
 * iOS
 */
.WriteBarIcon--ios {
  color: var(--vkui--color_icon_accent_themed);
}

.WriteBarIcon--ios.WriteBarIcon--mode-send,
.WriteBarIcon--ios.WriteBarIcon--mode-done {
  color: var(--vkui--color_icon_accent_themed);
  width: 48px;
  padding-left: 0;
  padding-right: 0;
}

.WriteBarIcon--ios.WriteBarIcon--mode-send:only-child,
.WriteBarIcon--ios.WriteBarIcon--mode-done:only-child {
  /* для одной иконки нужно компенсировать отступы */
  margin-right: -4px;
  margin-left: -4px;
}

/**
 * Impact:
 * Counter
 */
.WriteBarIcon {
  --vkui_internal--counter_inherit_color: var(--vkui--color_text_contrast_themed);
  --vkui_internal--counter_inherit_background: var(--vkui--color_background_positive);
}
