.Group {
  color: var(--vkui--color_text_primary);
  padding-top: 8px;
  padding-bottom: 8px;
}

.Group__separator--spacing,
.Group__separator--separator {
  display: none;
}

.Group--mode-plain + * + .Group__separator--separator {
  display: block;
}

@media (--sizeX-compact) {
  .Group--sizeX-none.Group--mode-none + * + .Group__separator--separator {
    display: block;
  }
}

.Group--mode-card + .Group__separator--spacing {
  display: block;
}

@media (--sizeX-regular) {
  .Group--sizeX-none.Group--mode-none + .Group__separator--spacing {
    display: block;
  }
}

.Group:last-of-type ~ .Group__separator {
  display: none;
}

.Group:last-of-type ~ .Group__separator--force,
.Group--mode-card:last-of-type + .Group__separator--spacing {
  display: block;
}

@media (--sizeX-regular) {
  .Group--sizeX-none.Group--mode-none:last-of-type + .Group__separator--spacing {
    display: block;
  }
}

.Group--mode-card.Group--padding-s {
  padding: 4px;
}

.Group--mode-card.Group--padding-m {
  padding: 8px;
}

@media (--sizeX-regular) {
  .Group--sizeX-none.Group--inside-modal.Group--padding-s,
  .Group--sizeX-none.Group--mode-none.Group--padding-s {
    padding: 4px;
  }

  .Group--sizeX-none.Group--inside-modal.Group--padding-m,
  .Group--sizeX-none.Group--mode-none.Group--padding-m {
    padding: 8px;
  }
}

.Group--sizeX-compact,
.Group--sizeX-compact.Group--mode-card {
  padding-left: 0;
  padding-right: 0;
}

@media (--sizeX-compact) {
  .Group--sizeX-none,
  .Group--sizeX-none.Group--mode-card {
    padding-left: 0;
    padding-right: 0;
  }
}

.Group--mode-card {
  background: var(--vkui--color_background_content);
  border-radius: var(--vkui--size_border_radius_paper--regular);
  position: relative;
}

@media (--sizeX-regular) {
  .Group--sizeX-none.Group--mode-none {
    background: var(--vkui--color_background_content);
    border-radius: var(--vkui--size_border_radius_paper--regular);
    position: relative;
  }
}

.Group--sizeX-compact.Group--mode-card:first-of-type {
  border-radius: 0 0 var(--vkui--size_border_radius_paper--regular)
    var(--vkui--size_border_radius_paper--regular);
}

@media (--sizeX-compact) {
  .Group--sizeX-none.Group--mode-card:first-of-type {
    border-radius: 0 0 var(--vkui--size_border_radius_paper--regular)
      var(--vkui--size_border_radius_paper--regular);
  }
}

/**
 * Изменено с ::after на ::before
 * потому что при ::after абсолютно позиционированный элемент накладывается
 * поверх любого другого абсолютно позиционированного элемента внутри Group,
 * например поверх Tooltip
 * См. пример: Slider c пропом withTooltip
 */
.Group--mode-card::before {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  box-shadow: inset 0 0 0 var(--vkui_internal--thin_border) var(--vkui--color_separator_primary);
}

@media (--sizeX-regular) {
  .Group--sizeX-none.Group--mode-none::before {
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
    box-shadow: inset 0 0 0 var(--vkui_internal--thin_border) var(--vkui--color_field_border_alpha);
  }
}

.Group--sizeX-compact.Group--mode-card::before {
  box-shadow: none;
}

@media (--sizeX-compact) {
  .Group--sizeX-none.Group--mode-card::before {
    box-shadow: none;
  }
}

.Group--mode-plain + .Group__separator,
.Group--mode-plain + .Group__separator + .Group__separator {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (--sizeX-compact) {
  .Group--sizeX-none.Group--mode-none + .Group__separator,
  .Group--sizeX-none.Group--mode-none + .Group__separator + .Group__separator {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.Group__description {
  display: block;
  padding: 4px 16px 16px;
  color: var(--vkui--color_text_secondary);
}

.Group .Group,
.Group .Group + .Group__separator {
  padding-left: 0;
  padding-right: 0;
}

.Group .Group:first-of-type {
  padding-top: 0;
}

.Group .Group:last-of-type {
  padding-bottom: 0;
}

/**
 * iOS
 */
.Group--ios .Group__description {
  padding: 4px 12px 16px;
}

/*
 * CMP:
 * PanelHeader
 */
:global(.vkuiInternalPanelHeader--vkcom) ~ .Group:first-of-type,
:global(.vkuiInternalPanelHeader--vkcom) + * .Group:first-of-type {
  position: relative;
  top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
