.Typography--normalize {
  display: block;
  margin: 0;
  padding: 0;
}

/* Мы утяжеляем селектор, чтобы перебить другие селекторы */
.Typography--weight-1.Typography--weight-1.Typography--weight-1 {
  font-weight: var(--vkui--font_weight_accent1);
}

.Typography--weight-2.Typography--weight-2.Typography--weight-2 {
  font-weight: var(--vkui--font_weight_accent2);
}

.Typography--weight-3.Typography--weight-3.Typography--weight-3 {
  font-weight: var(--vkui--font_weight_accent3);
}

/* stylelint-disable-next-line @project-tools/stylelint-atomic */
.Typography b {
  font-weight: var(--vkui--font_weight_accent1);
}
