.ActionSheet {
  overflow: hidden;
  box-shadow: var(--vkui--elevation3);
  width: calc(100% - 20px);
  max-width: var(--vkui--size_popup_small--regular);
  align-items: stretch;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  animation: animation-actionsheet-intro 0.2s var(--vkui--animation_easing_platform);
  padding: 8px 0;
  margin: 10px;
  border-radius: 12px;
  background: var(--vkui--color_background_modal);
}

.ActionSheet__header {
  color: var(--vkui--color_text_subhead);
  padding: 16px 20px;
}

.ActionSheet--closing {
  transform: translateY(calc(100% + 20px));
  transition: transform 0.2s var(--vkui--animation_easing_platform);
}

.ActionSheet__title + .ActionSheet__text {
  margin-top: 8px;
}

/**
 * iOS
 */
.ActionSheet--ios {
  width: 100%;
  animation: animation-actionsheet-intro 0.3s var(--vkui--animation_easing_platform);
  padding: 8px;
  margin: unset;
  background: transparent;
}

.ActionSheet--ios.ActionSheet--closing {
  transform: translateY(100%);
  transition: transform 0.3s var(--vkui--animation_easing_platform);
}

.ActionSheet--ios .ActionSheet__header {
  position: relative;
  overflow: hidden;
  border-radius: 14px 14px 0 0;
  text-align: center;
}

.ActionSheet--ios .ActionSheet__title {
  position: relative;
}

.ActionSheet--ios .ActionSheet__text {
  position: relative;
}

.ActionSheet--ios .ActionSheet__header::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--vkui--color_background_modal);
  content: '';
}

/**
 * Compact
 */

.ActionSheet--sizeY-compact .ActionSheet__header {
  padding: 12px 20px;
}

/**
 * Desktop version
 */

.ActionSheet--desktop {
  width: auto;
  height: auto;
  animation: none;
  margin: 0;
  max-width: 100%;
}

.ActionSheet--desktop.ActionSheet--ios {
  border-radius: 14px;
  padding: 0;
}

/**
* Animations
*/
@keyframes animation-actionsheet-intro {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}
