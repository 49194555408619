.Header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0;
  padding-left: var(--vkui--size_base_padding_horizontal--regular);
  padding-right: var(--vkui--size_base_padding_horizontal--regular);
  user-select: text;
  font-family: var(--vkui--font_family_base);
}

.Header__main {
  min-width: 0;
  color: var(--vkui--color_text_primary);
}

.Header__content {
  display: flex;
  align-items: center;
}

.Header__content-in,
.Header__subtitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Header__content--multiline {
  white-space: initial;
  word-break: break-word;
}

.Header__subtitle {
  display: block;
  color: var(--vkui--color_text_secondary);
}

.Header--mode-secondary:not(.Header--pi):not(.Header--with-subtitle) .Header__main,
.Header--mode-tertiary .Header__main {
  color: var(--vkui--color_text_secondary);
}

.Header__indicator {
  color: var(--vkui--color_text_secondary);
  margin-left: 6px;
  flex-shrink: 0;
}

.Header--mode-primary .Header__indicator {
  color: var(--vkui--color_text_subhead);
}

.Header:not(.Header--pi) .Header__indicator {
  align-self: center;
}

.Header--mode-secondary .Header__indicator {
  margin-left: 8px;
}

.Header__aside {
  white-space: nowrap;
  margin-left: 12px;
}

.Header__aside > :global(.vkuiIcon) {
  position: relative;
  color: var(--vkui--color_icon_secondary);
}

.Header__aside > :global(.vkuiIcon--24) {
  top: 3px;
}

.Header__aside > :global(.vkuiIcon--16) {
  top: 1px;
}

.Header__main {
  margin: 15px 0 9px;
}

.Header--mode-primary .Header__main {
  margin-bottom: 7px;
}

.Header--mode-primary .Header__content:not(:last-child) {
  margin-top: 0;
}

.Header--mode-primary .Header__content:last-child {
  margin-bottom: 2px;
}

.Header--mode-tertiary .Header__main {
  margin-top: 11px;
}

/**
 * iOS
 */

.Header--ios .Header__main {
  margin: 13px 0 9px;
}

.Header--ios.Header--mode-primary .Header__main {
  margin: 16px 0 7px;
}

.Header--ios.Header--mode-secondary .Header__main {
  margin: 14px 0 10px;
}

.Header--ios.Header--mode-tertiary .Header__main {
  margin: 12px 0 8px;
}

.Header--ios.Header--mode-primary .Header__content:not(:last-child) {
  margin-top: -1px;
}

.Header--ios.Header--mode-primary .Header__content:last-child {
  margin-bottom: initial;
}

/**
 * CMP:
 * Group
 *
 * TODO: Переделать отрицательные отступы https://github.com/VKCOM/VKUI/issues/3508
 */
:global(.vkuiInternalGroup--mode-plain):not(:first-of-type) > .Header:first-child,
:global(.vkuiInternalGroup--mode-plain):not(:first-of-type)
  > :global(.vkuiInternalTappable):first-child
  > .Header {
  margin-top: -16px;
}

@media (--sizeX-compact) {
  :global(.vkuiInternalGroup--sizeX-none.vkuiInternalGroup--mode-none):not(:first-of-type)
    > .Header:first-child,
  :global(.vkuiInternalGroup--sizeX-none.vkuiInternalGroup--mode-none):not(:first-of-type)
    > :global(.vkuiInternalTappable):first-child
    > .Header {
    margin-top: -16px;
  }
}

:global(.vkuiInternalGroup--mode-card) > .Header:not(.Header--mode-tertiary):first-child,
:global(.vkuiInternalGroup--mode-card)
  > :global(.vkuiInternalTappable):first-child
  > .Header:not(.Header--mode-tertiary) {
  margin-top: -4px;
}

@media (--sizeX-regular) {
  :global(.vkuiInternalGroup--sizeX-none.vkuiInternalGroup--mode-none)
    > .Header:not(.Header--mode-tertiary):first-child,
  :global(.vkuiInternalGroup--sizeX-none.vkuiInternalGroup--mode-none)
    > :global(.vkuiInternalTappable):first-child
    > .Header:not(.Header--mode-tertiary) {
    margin-top: -4px;
  }
}

/**
 * Impact:
 * Link
 */
.Header__aside {
  --vkui_internal--link-color: var(--vkui--color_text_accent);
}
