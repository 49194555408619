.hidden-shadow {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25), inset 0 -4px 3px #ECB57A, inset 0 0 3px 2px rgba(239, 196, 149, 0.38);
}

.visible-shadow {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25), inset 0 -4px 3px #2FB400, inset 0 0 3px 2px rgba(47, 180, 0, 0.38);
}

.hidden-rotate {
    transform: rotateY(0deg)
}

.visible-rotate {
    transform: rotateY(360deg)
}