.ring-animation {
    animation-name: ring-shake;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes ring-shake {
    0% {
        rotate: 0deg;
    }

    15% {
        rotate: 40deg
    }

    30% {
        rotate: -30deg
    }

    40% {
        rotate: 10deg;
    }

    50% {
        rotate: 0deg
    }

    100% {
        rotate: 0deg;
    }
}