.PanelHeaderButton {
  appearance: none;
  background: none;
  border: 0;
  box-shadow: none;
  display: block;
  color: currentColor;
  padding: 0;
  margin: 0;
  position: relative;
}

.PanelHeaderButton[disabled] {
  opacity: 0.6;
}

.PanelHeaderButton--primitive {
  height: 48px;
  line-height: 48px;
  padding: 0 12px;
}

/*
 * iOS
 */

.PanelHeaderButton--ios {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 17px;
}

.PanelHeaderButton--ios.PanelHeaderButton--primitive {
  height: 44px;
  line-height: 44px;
}

.PanelHeaderButton--ios :global(.vkuiIcon--24) {
  padding: 10px;
}

.PanelHeaderButton--ios :global(.vkuiIcon--28) {
  padding: 8px;
}

/*
 * Android
 */

.PanelHeaderButton--android.PanelHeaderButton--notPrimitive {
  border-radius: 50%;
}

.PanelHeaderButton--android :global(.vkuiIcon--24) {
  padding: 12px;
}

.PanelHeaderButton--android :global(.vkuiIcon--28) {
  padding: 10px;
}

/**
* VKCOM
*/
.PanelHeaderButton--vkcom {
  color: var(--vkui--color_text_secondary);
}

.PanelHeaderButton--vkcom > :not(:global(.vkuiInternalCounter)) {
  transition: opacity 0.3s;
  opacity: 0.7;
}

.PanelHeaderButton--vkcom.PanelHeaderButton--hover > :not(:global(.vkuiInternalCounter)),
.PanelHeaderButton--vkcom.PanelHeaderButton--active > :not(:global(.vkuiInternalCounter)) {
  opacity: 1;
}

.PanelHeaderButton--vkcom {
  display: flex;
  align-items: center;
  padding: 10px;
}

.PanelHeaderButton--vkcom.PanelHeaderButton--notPrimitive {
  border-radius: 50%;
}

.PanelHeaderBack--vkcom.PanelHeaderBack--has-label {
  padding-right: 11px;
}

@media (--sizeX-regular) {
  .PanelHeaderBack--ios.PanelHeaderBack--has-label:not(.PanelHeaderBack--sizeX-compact) {
    padding-right: 8px;
  }
}

.PanelHeaderBack--sizeX-compact.PanelHeaderBack--ios .PanelHeaderButton__label {
  display: none;
}

@media (--sizeX-compact) {
  .PanelHeaderBack--sizeX-none.PanelHeaderBack--ios .PanelHeaderButton__label {
    display: none;
  }
}
