.default-shadow {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25), inset 0 -4px 3px #ECB57A, inset 0 0 3px 2px rgba(239, 196, 149, 0.38);
}

.incorrect-shadow {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25), inset 0 -4px 3px #E01D1D, inset 0 0 2px rgba(224, 29, 29, 0.38);
}

.correct-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), inset 0 -3px 3px #2FB400, inset 0 0 3px 1px rgba(47, 180, 0, 0.38);
}

.perspective-10 {
    perspective: 10px;
}

.new-letter {
    transform: translate3d(0, -10px, 0);
    animation-name: letter-wrapper-appearance;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
}

.letter-disappearance {
    animation-name: letter-wrapper-disappearance;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    transform: translate3d(0, 0, 0);
}

@keyframes letter-wrapper-appearance {
    0% {
        transform: translate3d(0, -10px, 0);
    }

    33% {
        transform: translate3d(0, 4px, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes letter-wrapper-disappearance {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 100;
    }
    100% {
        transform: translate3d(0, -10px, 0);
        opacity: 0;
    }
}